<template>
  <div id="discover" class="mt-8">
    <v-container class="content py-12 mb-12">
      <h2 class="display-1 text-center mb-12 font-weight-bold primary--text">
        Current active WEARELOCALS websites include:
      </h2>
      <BrandTiles />
    </v-container>
    <div id="quotes" class="py-12 grey lighten-2">
      <v-container class="content">
        <h3 class="display-2 font-weight-bold primary--text">
          What they say...
        </h3>
        <v-row class="mt-10">
          <v-col cols="12" md="6">
            <blockquote class="blockquote font-italic pa-10">
              We love the ease-of-use of the #wearebs3 website. We were able to
              get our page and products set up in no time at all. I see this
              website being a great asset to businesses with no web-presence, in
              terms of being able to start selling online quickly and easily. It
              will also benefit businesses like ours who already have e-commerce
              websites as it gives the opportunity to reach new customers. We’re
              really excited to see more local businesses joining in!.
            </blockquote>
            <p class="font-weight-bold px-10 text-right primary--text">
              Lindsay, Co-Owner Toyville, North Street, Bristol
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <blockquote class="blockquote font-italic pa-10">
              The idea of wearebs3 was immediately appealing to us especially in
              these current times, to be able to take orders quickly and
              efficiently not only to local customers but to those slightly
              further afield in Bristol who may not know about the shop. The
              extra bonus is being able to link in with all the other amazing
              shops in BS3 and allow so many more people to shop easily from
              multiple retailers in one basket. We have been used as a pilot
              shop and as the orders have come in we have found them easy to
              deal with and the customers have given us great feedback. The back
              end side of the site is easy to use and allows us to enter our
              stock levels which is really helping us maintain a great
              availability. This platform has the ability to grow and become a
              great showcase for what BS3 has to offer!.
            </blockquote>
            <p class="font-weight-bold px-10 text-right primary--text">
              Stacey, Co-Owner Zero primary, North Street, Bristol
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import BrandTiles from "@/components/BrandTiles.vue";

export default {
  name: "Discover",
  components: {
    BrandTiles,
  },
};
</script>
